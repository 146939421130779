import axios from "axios";
import authHeader from "../auth-header";
const API_URL = process.env.REACT_APP_API_URL

function getProjectGroups() {
    return axios
        .get(
            API_URL + 'projectgroups', { headers: authHeader() }
        )
        .then(response => {
            return response.data.projectgroups
        });
}

export default getProjectGroups;